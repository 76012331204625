@import 'variables.scss';

* {
  font-family: $fontFamily !important;
}

body {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: $darkGray;
}

p {
  font-size: 1.1em;
}

li {
  list-style-type: none;
  &:focus {
    outline: none !important;
  }
}

input, button {
  &:focus {
    outline: 0;
  }
}

.white-button {
  margin: 8px;
  background-color: $white;
  color: $primaryRedColor;
  font-size: 18px;
  font-weight: 600;
  padding: 5px 40px;
  cursor: pointer;
  border: 0;
  border-radius: 4px;
}

.red-button {
  margin: 8px;
  background-color: $primaryRedColor;
  color: $white;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: normal;
  padding: 5px 40px;
  cursor: pointer;
  border: 0;
  border-radius: 4px;
}

.underline-button {
  margin: 8px;
  background-color: $white;
  color: $primaryRedColor;
  border-color: $primaryRedColor;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 5px 40px;
  cursor: pointer;
  border-radius: 4px;
}

.black-button {
  @extend .white-button;
  color: black
}

.disabled-button {
  margin: 8px;
  background-color: $grayLine;
  color: $lightGray;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: normal;
  padding: 5px 40px;
  cursor: not-allowed;
  border: 0;
  border-radius: 4px;
}

.not-found {
  img {
    height: 220px;
    width: 220px;
  }

  h1 {
    font-size: 5em;
    color: $primaryRedColor;
    margin: -15px 0;
  }
}

.logo-v {
  width: 75px;
  height: 75px;
}

.logo-venemergencia {
  height: 75px;
  width: 230px;
}

.logo-m {
  width: 200px;
}

.content {
  min-height: 100vh;
}

.underline {
  text-decoration: underline;
}

/* FLEX-BOX */
.flex {
  display: flex;
}

.pc-c {
  place-content: center;
}

.jc-c {
  justify-content: center;
}

.jc-b {
  justify-content: space-between;
}

.jc-e {
  justify-content: space-evenly;
}

.jc-a {
  justify-content: space-around;
}

.jc-s {
  justify-content: flex-start
}

.flex-row {
  flex-direction: row !important;
}

.flex-col {
  flex-direction: column;
}

.align-ic {
  align-items: center;
}

.align-sb {
  align-self: baseline;
}

.align-sc {
  align-self: center;
}

.flex-center {
  @extend .flex;
  @extend .align-ic;
}

.flex-full {
  @extend .flex;
  @extend .align-ic;
  @extend .jc-c;
}

.fje {
  @extend .flex;
  justify-content: flex-end;
}

.wrap {
  flex-wrap: wrap;
}

/* FLEX-BOX */

/* CONTAINER */
.container {
  margin: 0 auto;
  width: 80%;
}
/* CONTAINER */

.color-red {
  color: $primaryRedColor;
}

.color-green {
  color: $greenLight;
}

.color-white {
  color: $white;
}

.color-light {
  color: $lightGray;
}

.color-gray {
  color: #43484d;
}

.color-dark {
  color: $darkGray;
}

.back-gray {
  background-color: $darkGray !important;
}

.back-red {
  background-color: $primaryRedColor !important;
}

.back-pink {
  background-color: #FB314A;
}

.back-darkgray{
  background-color: #fafafa;
}

.r-8-5p {
  right: 8.5%;
}

.r-39-5p {
  right: 43%;
}

.p-1em{
  padding: 1em;
}

.p-v20 {
  padding: 20px 0;
}

.p-v48 {
  padding: 48px 0;
}

.p-b0-3em {
  padding-bottom: 0.3em;
}

.p-b0-1p {
  padding-bottom: 1%;
}

.p-t0-3em {
  padding-top: 0.3em;
}

.p-t10 {
  padding-top: 10px;
}

.p-t20 {
  padding-top: 20px;
}

.p-l10 {
  padding-left: 10px;
}

.p-l15 {
  padding-left: 15px;
}

.p-l4p {
  padding-left: 4%;
}

.p-r20 {
  padding-right: 20px;
}

.p-r15 {
  padding-right: 15px;
}

.p-s20 {
  padding: 0 20px;
}

.p10 {
  padding: 10px;
}

.p20 {
  padding: 20px;
}

.p40 {
  padding: 40px;
}

.np {
  padding: 0 !important;
}

.in-f{
  display: inline-flex;
}

.p-inline-start0{
  padding-inline-start: 0px;
}

.p-inline-start40{
  padding-inline-start: 40px;
}

.c-p {
  cursor: pointer;
}

.m-0-450px {
  margin: 0 450px;
}

.m-s8 {
  margin: 0 8px;
}

.m-t80 {
  margin-top: 80px;
}

.m-t48 {
  margin-top: 48px;
}

.m-t20 {
  margin-top: 20px;
}

.m-t10 {
  margin-top: 10px;
}

.m-t18em{
  margin-top: 18em;
}

.m-l0 {
  margin-left: 0 !important;
}

.m-l20 {
  margin-left: 20px;
}

.m-l55 {
  margin-left: 55px;
}

.m-l10 {
  margin-left: 10px;
}

.m-l15 {
  margin-left: 15px;
}

.m-l5 {
  margin-left: 5px;
}

.m-l5p {
  margin-left: 5%;
}

.m-s5 {
  margin: 0 5px;
}

.m-r0 {
  margin-right: 0 !important;
}

.m-r5 {
  margin-right: 5px;
}

.m-r20 {
  margin-right: 20px;
}

.m-r2p {
  margin-right: 2%;
}

.m-r14p {
  margin-right: 14%;
}

.m-b48 {
  margin-top: 48px;
}

.m-b20 {
  margin-bottom: 20px;
}

.m-b10 {
  margin-bottom: 10px;
}

.m-b0 {
  margin-bottom: 0;
}

.m5 {
  margin: 5px;
}

.m-a {
  margin: auto;
}

.nm {
 margin: 0;
}

.max-w75 {
  max-width: 75%;
}

.fw {
  width: 100%;
}

.fh {
  height: 100%;
}

.fh-12px {
  height: 12px;
}

.fh-9px {
  height: 0.9em;
}

.fwh {
  @extend .fw;
  @extend .fh;
}

.h-200 {
  height: 200px;
}

.w-12 {
  width: 12px;
}

.w-14 {
  width: 14px;
}

.w-20px {
  width: 20px;
}

.w-40p {
  width: 40%;
}

.w-17em {
  width: 1.7em; 
}

.w-50p {
  width: 50%;
}

.w-60p {
  width: 60%;
}

.h-70 {
  height: 70px;
}

.h-80vh {
  height: 80vh;
}

.w-full {
  width: 100%;
}

.nav-text {
  font-size: $navSize;
  color: $darkGray;
  line-height: $navLineHeight;
}

.x-small {
  font-size: 0.75rem;
}

.sm-small {
  font-size: 0.875rem;
}

.f-14 {
  font-size: 14px
}

.f-1em {
  font-size: 1em;
}

.f-11em {
  font-size: 1.1em;
}

.f-30px {
  font-size: 30px;
}

.f-2vw {
  font-size: calc(1rem + 1vw);
}

.title {
  font-size: 2em;
  @extend .strong;
}

.subtitle {
  font-size: 1.5em;
  @extend .strong;
  line-height: 30px;
}

.x-strong {
  font-weight: bolder;
}

.strong {
  font-weight: bold;
}

.f-normal {
  font-weight: normal;
}

.lh-36 {
  line-height: 36px;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.icon-big-s {
  font-size: 30px;
}

.d-b {
  display: block;
}

.w-35 {
  width: 35%;
}

.b-ss{
  border-style: solid;
}

.b-cgray{
  border-color: #b8b8b8;
}

.b-w1px{
  border-width: 1px;
}

.b-r3-5p {
  border-radius: 3.5px;
}

.b-r4 {
  border-radius: 4px;
}

.b-r-10-10-10 {
  border-radius: 10px 10px 10px;
}

.b-1-s-color {
  border: 1px solid #b8b8b8;
}

.op-05 {
  opacity: 0.5;
}

.fs-resp {
  font-size: calc(1em + 1vw);
}

.top-3-6em {
  top: 3.6em;
}

.top-3-9em {
  top: 3.9em;
}

.top-5em {
  top:5em;
}

.top-7em {
  top:7em;
}

.l-16p{
  left: 16%;
}

.pos-ab {
  position: absolute;
}

.t-rotate-45d{
  transform: rotate(45deg);
}

.txt-sw-1p-2p-999 {
  text-shadow: 1px 2px #999;
}

.b-sw-0-24px-24px-0-b {
  box-shadow: 0 24px 24px 0 rgba(0, 0, 0, 0.3);
}

.float-l {
  float: left;
}

.p-ab{
  position: absolute;
}

.img-create-user{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 25em;
  background-image: url('https://venemergencia-semm.s3.amazonaws.com/logos/manos_auto_x2.jpg');
  background-position: center;
  background-size: cover;
}

.img-succes-afiliation{
  display: flex;
  width: 100%;
  height: 22em;
  margin-bottom: 1em;
  background-image: url('../assets/images/photos/slide1.png');
  background-position: center;
  background-size: cover;
}

.fixed-toast {
  box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12);
  background: $primaryRedColor;
  color: $white;
  padding: 15px 30px;
  font-size: 1.1em;
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 15px;
  min-width: 100px;
  max-width: 568px;
  border-radius: 4px;
  z-index: 1;
}

/* RESPONSIVE STYLE */

@media #{$sm} {
  .container {
    width: 680px;
    max-width: 95vw;
  }
}

@media #{$md} {
  .container {
    width: 1060px;
    margin-right: 4.4%;
  }
}

@media #{$lg} {
  .container {
    width: 1080px;
  }
}

@media #{$xl} {
  .container {
    width: 1080px;
  }
}

@media #{$xs} {
  .container {
    width: 90%;
  }

  .col-xs {
		flex-direction: column;
	}

  .not-found {
    text-align: center;
    flex-direction: column;
    height: inherit !important;
    div {
      margin-left: 0 !important;
    }
  }
}
/* RESPONSIVE STYLE */

/* catamaran-700 - latin */
@font-face {
  font-family: 'Catamaran';
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/catamaran/catamaran-v5-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Catamaran Bold'), local('Catamaran-Bold'),
       url('../assets/fonts/catamaran/catamaran-v5-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/catamaran/catamaran-v5-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/catamaran/catamaran-v5-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/catamaran/catamaran-v5-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/catamaran/catamaran-v5-latin-700.svg#Catamaran') format('svg'); /* Legacy iOS */
}

/* catamaran-regular - latin */
@font-face {
  font-family: 'Catamaran';
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/catamaran/catamaran-v5-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Catamaran Regular'), local('Catamaran-Regular'),
       url('../assets/fonts/catamaran/catamaran-v5-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/catamaran/catamaran-v5-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/catamaran/catamaran-v5-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/catamaran/catamaran-v5-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/catamaran/catamaran-v5-latin-regular.svg#Catamaran') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'icons';
  font-display: auto;
  src:  url('../assets/fonts/icon-font/icons.eot?4dfyjv');
  src:  url('../assets/fonts/icon-font/icons.eot?4dfyjv#iefix') format('embedded-opentype'),
    url('../assets/fonts/icon-font/icons.ttf?4dfyjv') format('truetype'),
    url('../assets/fonts/icon-font/icons.woff?4dfyjv') format('woff'),
    url('../assets/fonts/icon-font/icons.svg?4dfyjv#icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-laptop-medical-solid:before {
  content: "\61";
}
.icon-heartbeat-solid:before {
  content: "\62";
}
.icon-briefcase-medical-solid:before {
  content: "\63";
}
.icon-ambulance-solid:before {
  content: "\64";
}
.icon-user-md-solid:before {
  content: "\65";
}
.icon-user-solid:before {
  content: "\66";
}
.icon-instagram-brands:before {
  content: "\67";
}
.icon-facebook-brands:before {
  content: "\68";
}
.icon-twitter-brands:before {
  content: "\69";
}
.icon-bars-solid:before {
  content: "\6a";
}
.icon-home-solid:before {
  content: "\6b";
}
