
@import '../../theme/variables';

/* ABOUT US */
.card-information {
  width: calc((100% / 3) - 20px);
  margin: 0 10px;
  border-top: 5px $primaryRedColor solid;
}

/* ABOUT US */

/* Loadding FullScreen */

.loadding-component {
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, .7);
  z-index: 99999;
  top: 0;
  left: 0;
  transition: background-color 600ms ease-in-out;
}

.splash-screen {
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primaryRedColor;
  z-index: 99999;
  top: 0;
  left: 0;
  transition: background-color 600ms ease-in-out;

  .logo {
    width: auto;
    height: 40vh;
  }
}


.spinner{
  position: relative;
  width: 15em;
  height: 15em;
  background-color: $primaryRedColor;
  overflow: hidden;
  border-radius: 50%; 
}

.spinner::after{
  content: "";
  display: block;
  width: 9.5em;
  height: 9.5em; 
  background: $white;
  position: absolute;
  left: 7.5em;
  top: -1.5em; 
  transform-origin: left bottom;
  animation: spin 2s linear infinite;
}

.spinner::before{
  content: url('../../assets/images/logo-v-blanco.svg');
  display: block;
  width: 14em;
  height: 14em;
  position: absolute;
  border-radius: 100%;
  margin: 0.5em;
  z-index: 3;
  background-color: $primaryRedColor;
}

/* In Chrome and Safari we might see a flickering effect when using CSS transforms or animations. The following 3d declarations can be used to fix the issue: Maybe it can :) */

@keyframes spin{
 0%{
    transform: rotate3d(0,0,1,0deg) skewX(-65deg) scale3d(1, 1, 1);
 }
 50%{
    transform: rotate3d(0,0,1,360deg) skewY(45deg) scale3d(1, 1.4, 1);
 }
 100%{
    transform: rotate3d(0,0,1,720deg) skewX(-65deg) scale3d(1, 1, 1);
 }
}

/* Loadding FullScreen */

/* Alert Dialog */
.close-dialog {
  background: #f3f3f3;
  padding: 5px;
  color: $lightGray;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 15px;
  }
}
/* Alert Dialog */

// COVID19 dialog
.bg-dialog {
  background-color: $primaryRedColor;
  padding-top: 20px;
  padding-bottom: 20px;
  .logo-dialog {
    height: 80px;
    width: auto;
  }
}

.checkBox {
  color: $white !important;
}

@media #{$xs} {
  .card-information {
    width: 100%;
    margin: 10px 0;
  }
}