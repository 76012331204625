// FontFamily venemergencia
$fontFamily: "Catamaran", Helvetica, sans-serif;

// Primary red color
$primaryRedColor: #e32726;
$backRedColor: #faa7a7;

// Seccondary LightBlue Color
$lightBlueColor: #21aab7;

// Green color
$greenLight: #2fd254;

// DarkGray text
$darkGray: #333;

// LightGray text
$lightGray: #707070;

// GrayLine
$grayLine: #ccc;

$white: #fff;
$black: #000;
$blackTransparent: rgba(0, 0, 0, 0.5);

/* FONT */
$navSize: 16px;
$navLineHeight: 1em;
/* FONT */

/* Media queries */
$xs-max: 599px; // xs max-width
$sm-max: 1049px; // sm max-width
$md-max: 1279px; // md max-width
$lg-max: 1919px; // lg max-width
$sm-min: 600px; // sm min-width
$md-min: 1050px; // md min-width
$lg-min: 1280px; // lg min-width
$xl-min: 1920px; // xs min-width
$xs: 'only screen and (max-width: 599px)'; // mobiles
$sm: 'only screen and (min-width: 600px) and (max-width: 1049px)'; // tablets
$md: 'only screen and (min-width: 1050px) and (max-width: 1279px)'; // laptops
$lg: 'only screen and (min-width: 1280px) and (max-width: 1919px)'; //desktop
$xl: 'only screen and (min-width: 1920px) and (max-width: 5000px)'; // widescreen
$lt-sm: 'only screen and (max-width: 599px)';
$lt-md: 'only screen and (max-width: 1049px)';
$lt-lg: 'only screen and (max-width: 1279px)';
$lt-xl: 'only screen and (max-width: 1919px)';
$gt-xs: 'only screen and (min-width: 600px)';
$gt-sm: 'only screen and (min-width: 1050px)';
$gt-md: 'only screen and (min-width: 1280px)';
$gt-lg: 'only screen and (min-width: 1920px)';
/* Media queries */

:export {
  primaryRedColor: $primaryRedColor;
  backRedColor: $backRedColor;
  lightBlueColor: $lightBlueColor;
  greenLight: $greenLight;
  darkGray: $darkGray;
  lightGray: $lightGray;
  grayLine: $grayLine;
  white: $white;
  black: $black;
}